import { gql } from 'apollo-angular';

export const Query_GetServiceVisitWorkers = gql`
  query ($search: DtoPaginatedFilter!) {
    getServiceVisitWorkers(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          status
          serviceVisitId
          workerId
          estTravelDistance
          estTravelTime
          estTravelFromLatLng
          estTravelPolyline
          actualTravelDistance
          actualTravelTime
          actualTravelFromLatLng
          actualTravelPolyline
          travelStartDateTime
          travelEndDateTime
          onSiteStartDateTime
          onSiteEndDateTime
          isSigned
          scheduledStartDateTime
          worker {
            displayName
            workerNum
            id
            user {
              id
              email
              avatarURL
            }
          }
          serviceVisit {
            id
            serviceVisitNum
            contactName
            contactEmail
            address1
            address2
            city
            state
            scheduledStartDateTime
            customer {
              name
            }
          }
        }
      }
    }
  }
`;

//  estDistance            Float?              @default(0)
// estTravelTime          Float?              @default(0)
// estTravelFromLatLng    String?             @default("")
// estTravelPolyline      String?             @default("")
// actualDistance         Float?              @default(0)
// actualTravelTime       Float?              @default(0)
// actualTravelFromLatLng String?             @default("")
// actualTravelPolyline   String?             @default("")
// actualStartDateTime    DateTime?
// actualEndDateTime      DateTime?
// status                 String?
// travelStartDateTime    DateTime?
// travelEndDateTime      DateTime?
// onSiteStartDateTime    DateTime?
// onSiteEndDateTime      DateTime?
