@if (workOrderId) {
  <next-object-list
    id="checklistList"
    object="Checklist"
    [selectFirstRow]="true"
    (onSelect)="selectChecklist($event)"
    [hideToolBar]="true"
    [filters]="[
      {
        column: 'workOrderId',
        value: workOrderId,
      },
    ]"
    height="200"
  ></next-object-list>
}

<br />
@if (this.checklist?.id) {
  <div class="row">
    <div class="col">
      @for (group of groupEntries; track group.name) {
        <div class="d-flex align-items-center">
          <h5 class="mb-2 mt-3">
            {{ group.name }}
          </h5>
          @if (group.name !== 'Detail') {
            <!-- <nb-badge [status]="getIconColor(group.name)" class="ml-2">
              {{ group?.items?.length }}
            </nb-badge> -->
          }
        </div>
        <nb-card>
          <nb-list>
            @for (item of group.items; track item.id) {
              <nb-list-item class="d-flex w-100" style="flex-direction: column">
                <div class="d-flex">
                  @if (group.name !== 'Detail') {
                    <nb-icon
                      style="min-width: 30px"
                      [icon]="getIconName(item.status)"
                      [status]="getIconColor(item.status)"
                      class="mr-3"
                    >
                    </nb-icon>
                  }
                  <div class="flex-grow-1">
                    <strong>{{ item.label }}</strong>

                    @if (item.notes) {
                      <p class="text-hint mb-0">{{ item.notes }}</p>
                    }
                  </div>
                  @if (group.name === 'Detail') {
                    {{ item.value }}
                  }
                </div>
                <div class="d-flex mt-2">
                  <span style="width: 40px"></span>
                  @for (
                    attachment of lineAttachments(item?.id);
                    track attachment.id
                  ) {
                    @if (attachment.fileName.endsWith('mp4')) {
                      <i
                        (click)="displayImage(attachment)"
                        style="
                          font-size: 3rem;
                          margin-left: 5px;
                          color: lightcoral;
                        "
                        class="fa-regular fa-file-video"
                      ></i>
                    } @else {
                      <img
                        (click)="displayImage(attachment)"
                        style="
                          max-height: 140px;
                          border-radius: 5px;
                          margin-left: 5px;
                        "
                        [src]="attachment?.signedURL"
                      />
                    }
                  }
                </div>
              </nb-list-item>
            }
          </nb-list>
        </nb-card>
      }
    </div>
    <div [hidden]="true" class="col mt-3">
      <h5>Attachments</h5>
      <next-attachment
        #attachmentComponent
        [disableAdd]="true"
        object="Checklist"
        [refId]="checklist?.id"
        (attachments)="attachments = $event"
      ></next-attachment>
    </div>
  </div>
}
